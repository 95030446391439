<template>
    <div>
        <BuyerHeader v-if="isLatestTypeBuyer" />
        <SellerHeader v-if="isLatestTypeSeller" />
    </div>
</template>

<script>
import pageStoreMixin from '@/mixins/pageStore.mixin';
import BuyerHeader from '@/components/header/BuyerHeader.vue';
import SellerHeader from '@/components/header/SellerHeader.vue';

export default {
    name: 'UserHeader',
    components: { SellerHeader, BuyerHeader },
    mixins: [pageStoreMixin],
};
</script>

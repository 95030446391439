/**
 * {@link https://vuex.vuejs.org/zh/guide/modules.html}
 * */

// oms/need/getById
// initial state
const state = () => ({
    $userInfo: {}, // userInfo
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
    set$userInfo(state, $userInfo) {
        state.$userInfo = $userInfo;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

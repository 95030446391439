<template>
    <div class="header bg-red pointer select-none">
        <div class="header-content page-center flex-v-center flex-between">
            <div class="flex-v-center">
                <div @click="handleHome">
                    <img
                        height="60"
                        src="../../assets/img/logo/white.png"
                        alt=""
                    />
                </div>
                <div class="flex-v-center ml30 demand-select c-white">
                    <div
                        class="demand-select-item flex-v-center"
                        :class="{ active: isBuyerBill }"
                        @click="handleToBill"
                    >
                        <Icon class="mr5" size="16" type="md-albums" /> 发需求
                    </div>
                    <!--                    <div class="demand-select-item-col bg-white"></div>-->
                    <div
                        class="demand-select-item flex-v-center"
                        :class="{ active: isBuyerHome }"
                        @click="handleToBillHome"
                    >
                        <Icon size="16" class="mr5" type="md-person" />我的需求
                    </div>
                    <!--                    <div class="demand-select-item-col bg-white"></div>-->
                    <!--                    <div class="demand-select-item flex-v-center">-->
                    <!--                        <Icon size="16" class="mr5" type="md-people" /> 团购-->
                    <!--                    </div>-->
                </div>
            </div>

            <div class="flex-v-center c-white">
                <div class="flex-v-center mr20" @click="handleToSellerCenter">
                    <span class="mr5">切换为卖家</span>
                    <Icon size="16" class="mr5" type="md-people" />
                </div>
                <div class="flex-v-center">
                    <Account />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Account from './share/Account.vue';
import pageStoreMixin from '@/mixins/pageStore.mixin';

export default {
    name: 'BuyerHeader',
    components: { Account },
    mixins: [pageStoreMixin],
    computed: {
        isLoginPage() {
            return this.$route.path.startsWith('/login');
        },
        isBuyerBill() {
            return this.$route.path.startsWith('/home/buyer/bill');
        },
        isBuyerHome() {
            return this.$route.path.startsWith('/home/buyer/index');
        },
        isSeller() {
            return this.$route.path.startsWith('/home/seller');
        },
    },
    mounted() {
        this.setLatestTypeBuyer();
    },
    methods: {
        handleHome() {
            // /home/main/index
            if (this.$route.path !== '/home/main/index') {
                this.$router.push('/home/main/index');
            }
        },
        handleToBill() {
            if (this.$route.path !== '/home/buyer/bill') {
                this.$router.push('/home/buyer/bill');
            }
        },
        handleToBillHome() {
            if (this.$route.path !== '/home/buyer/index') {
                this.$router.push('/home/buyer/index');
                return;
            }

            try {
                window.registerUpdateRunner();
                this.$Message.success('刷新成功');
            } catch (e) {
                // ignore
            }
        },
        handleToSellerCenter() {
            if (this.$route.path !== '/home/seller/index') {
                this.$router.push('/home/seller/index');
            }
        },
    },
};
</script>
<style scoped lang="less">
@import './share/header';
</style>

<template>
    <div class="layout">
        <div v-show="!isLoginPage">
            <BuyerHeader v-if="isBuyer" />
            <SellerHeader v-if="isSeller" />
            <UserHeader v-if="isUser" />
            <MainHeader v-if="isMain" />
        </div>

        <div :class="isLoginPage ? '' : 'page-center root-content'">
            <router-view />
        </div>
        <PageBottom />
    </div>
</template>

<script>
import BuyerHeader from '../components/header/BuyerHeader.vue';
import SellerHeader from '../components/header/SellerHeader.vue';
import UserHeader from '../components/header/UserHeader.vue';
import PageBottom from '../components/PageBottom.vue';
import MainHeader from '../components/header/MainHeader.vue';
import userInfoMixin from '@/mixins/userInfo.mixin';
import currentOrgMixin from '@/mixins/currentOrg.mixin';

export default {
    name: 'App',
    components: {
        MainHeader,
        PageBottom,
        UserHeader,
        SellerHeader,
        BuyerHeader,
    },
    mixins: [userInfoMixin, currentOrgMixin],
    computed: {
        isLoginPage() {
            return this.$route.path.startsWith('/login');
        },
        isBuyer() {
            return this.$route.path.startsWith('/home/buyer');
        },
        isSeller() {
            return this.$route.path.startsWith('/home/seller');
        },
        isUser() {
            return this.$route.path.startsWith('/home/user');
        },
        isMain() {
            return this.$route.path.startsWith('/home/main');
        },
    },
    created() {
        this.get$currentOrg();
    },
};
</script>

<style scoped lang="less">
.layout {
    background: #f5f7f9;
    position: relative;

    .root-content {
        overflow: hidden;
        padding: 88px 0 40px;
    }
}
</style>

const getNotReadCount = (params = {}) => {
    return $api.get('/cms/notice/getNotReadCount', params);
};
const getListByMemberId = (params = {}) => {
    return $api.get('/cms/notice/getListByMemberId', params);
};
const del = (params = {}) => {
    return $api.post('/cms/notice/delete', params);
};
const updateIsRead = (params = {}) => {
    return $api.post('/cms/notice/updateIsRead', params);
};
// 批量已读
const noticeUserIdList = (params = {}) => {
    return $api.post('/cms/notice/noticeUserIdList', params);
};
// 批量删除
const noticeIdList = (params = {}) => {
    return $api.post('/cms/notice/noticeIdList', params);
};

export default {
    getNotReadCount,
    getListByMemberId,
    del,
    updateIsRead,
    noticeUserIdList,
    noticeIdList,
};

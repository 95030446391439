<template>
    <div class="i-city-selector">
        <Row>
            <Col :span="8">
                <Select
                    :disabled="disabled"
                    class="wp100 pdl4"
                    :value="editValue[0]"
                    @on-change="handleProvince"
                >
                    <Option
                        v-for="it of options.province"
                        :key="it.text"
                        :value="it.value"
                    >
                        {{ it.text }}
                    </Option>
                </Select>
            </Col>
            <Col
                v-if="editValue[0] && !isReserveCodeHide(editValue[1])"
                :span="8"
            >
                <Select
                    :disabled="disabled"
                    class="wp100 pdl4"
                    :value="editValue[1]"
                    @on-change="handleCity"
                >
                    <Option
                        v-for="it of options.city"
                        :key="it.text"
                        :value="it.value"
                    >
                        {{ it.text }}
                    </Option>
                </Select>
            </Col>
            <Col
                v-if="editValue[1] || isReserveCodeHide(editValue[1])"
                :span="8"
            >
                <Select
                    :disabled="disabled"
                    class="wp100 pdl4"
                    :value="editValue[2]"
                    @on-change="handleRegion"
                >
                    <Option
                        v-for="it of options.region"
                        :key="it.text"
                        :value="it.value"
                    >
                        {{ it.text }}
                    </Option>
                </Select>
            </Col>
        </Row>
    </div>
</template>

<script>
import cityUtil from '../util/cityUtil';

// 北京[110000, 110100]
// 天津 [120000, 120100]
// 上海 [310000, 310100]
// 重庆 [500000, 500100]
const reserveCodes = [
    [110000, 110100], // 北京
    [120000, 120100], // 天津
    [310000, 310100], // 上海
    [500000, 500100], // 重庆
];

export default {
    props: {
        value: {},
        disabled: {
            default: false,
        },
    },
    data() {
        return {
            editValue: [...this.value],
            options: this.getInitOpts(),
            cityData: cityUtil.getCityJSON(),
        };
    },
    methods: {
        updatePropValue() {
            this.editValue = [...this.value];
            this.options = this.getInitOpts();
        },
        getInitOpts() {
            let [provinceCode, cityCode, regionCode] = this.value;
            let province = cityUtil.getCityJSON();
            let city = [];
            let region = [];
            if (provinceCode) {
                let item = province.find((it) => it.value === provinceCode);
                city = _.get(item, 'children') || [];
            }

            if (cityCode) {
                let item = city.find((it) => it.value === cityCode);
                region = _.get(item, 'children') || [];
            }

            return { province, city, region };
        },
        isReserveCodeHide(code) {
            return reserveCodes.find((it) => it[1] === code);
        },
        handleChange(value) {
            this.$emit('input', value);
        },
        handleProvince(code) {
            this.editValue = [code];
            this.handleChange([]);
            let item = this.options.province.find((it) => it.value === code);
            this.options.city = _.get(item, 'children') || [];
            this.options.region = [];

            for (let it of reserveCodes) {
                if (code === it[0]) {
                    this.handleCity(it[1]);
                    break;
                }
            }
        },
        handleCity(code) {
            this.editValue = [this.editValue[0], code];
            this.handleChange([]);
            let item = this.options.city.find((it) => it.value === code);
            this.options.region = _.get(item, 'children') || [];
        },
        handleRegion(code) {
            this.editValue = [this.editValue[0], this.editValue[1], code];

            if (!code) {
                this.handleChange([]);
            } else {
                this.handleChange([...this.editValue]);
            }
        },
    },
};
</script>

<style lang="less">
.i-city-selector {
    min-width: 300px;
}
</style>

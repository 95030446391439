import Vue from 'vue';
import VueRouter from 'vue-router';
import home from './home';
import Login from '@/pages/login/Index';

Vue.use(VueRouter);

const routes = [
    ...home,
    {
        path: '/login',
        component: Login,
    },
    {
        path: '*',
        redirect: '/home/main/index',
    },
];

export default new VueRouter({ routes });

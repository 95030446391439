const STORE_KEY = '__LATEST_TYPE__';
const typeMap = {
    buyer: 'buyer',
    seller: 'seller',
};

export default {
    computed: {
        isLatestTypeBuyer() {
            let s = localStorage.getItem(STORE_KEY) || typeMap.buyer;
            return s === typeMap.buyer;
        },
        isLatestTypeSeller() {
            let s = localStorage.getItem(STORE_KEY) || typeMap.buyer;
            return s === typeMap.seller;
        },
    },
    methods: {
        setLatestTypeBuyer() {
            localStorage.setItem(STORE_KEY, typeMap.buyer);
        },
        setLatestTypeSeller() {
            localStorage.setItem(STORE_KEY, typeMap.seller);
        },
    },
};

// 买家
const list = (params = {}) => {
    return $api.get('/pms/brand/list', params);
};

const sava = (params = {}) => {
    return $api.post('/pms/brand/sava', params);
};

export default { list, sava };

// promisify

const recharge = (params = {}) => {
    return $api.post('/fms/amount/recharge', params);
};
const rechargeNative = (params = {}) => {
    return $api.get('/fms/amount/recharge-native/' + params.money, params);
};
const rechargeNativeQuery = (id) => {
    return $api.get(
        `/fms/amount/recharge-native-query/${id}`,
        {},
        { hideLoading: true },
    );
};
const detail = (params = {}) => {
    return $api.get('/fms/amount/detail', params);
};
const amountLoglist = (params = {}) => {
    return $api.get('/fms/amountLog/list', params);
};

// 开发票
const invoicing = (params = {}) => {
    return $api.post('/fms/invoice/invoicing', params);
};

// 开发票
const amountLoglistFp = (params = {}) => {
    return $api.get('/fms/amountLog/list-fp', params);
};

export default {
    recharge,
    rechargeNative,
    rechargeNativeQuery,
    detail,
    amountLoglist,
    invoicing,
    amountLoglistFp,
};

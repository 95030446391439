import { createNamespacedHelpers } from 'vuex';

const { mapState, mapMutations } = createNamespacedHelpers('userInfo');

export default {
    computed: {
        ...mapState(['$userInfo']),
        userStatusStr() {
            if (_.get(this.$userInfo, 'status') === 1) {
                return $util.getOptName(1, Object.values($const.memberStatus), {
                    prop: 'value',
                    text: 'display',
                });
            } else {
                return $util.getOptName(0, Object.values($const.memberStatus), {
                    prop: 'value',
                    text: 'display',
                });
            }
        },
    },
    methods: {
        ...mapMutations(['set$userInfo']),
        clearUserInfo() {
            this.set$userInfo({});
        },
        async get$userInfo(params = {}) {
            await this.$nextTick();
            let userData = await $service.memeber.getUserInfo(params);
            let data = _.get(userData, 'data');
            this.set$userInfo(data);
            return data;
        },
    },
};

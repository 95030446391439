const buyerIndex = [
    {
        name: '买家中心',
        path: '/home/buyer/index',
        style: {
            color: 'rgba(0, 191, 191, 1)',
        },
        icon: 'ios-alert',
    },
];

// 买家相关
export default [
    // 首页
    {
        path: 'buyer/index',
        meta: {
            breadcrumb: [...buyerIndex],
        },
        component: () =>
            import(
                /* webpackChunkName: "buyer-module" */ '../../../pages/home/buyer/Index'
            ),
    },
    // 发单
    {
        path: 'buyer/bill',
        meta: {
            breadcrumb: [
                ...buyerIndex,
                {
                    name: '发单',
                },
            ],
        },
        component: () =>
            import(
                /* webpackChunkName: "buyer-module" */ '../../../pages/home/buyer/Bill'
            ),
    },
    // buyer查看
    {
        path: 'buyer/view',
        meta: {
            breadcrumb: [
                ...buyerIndex,
                {
                    name: '查看',
                },
            ],
        },
        component: () =>
            import(
                /* webpackChunkName: "buyer-module" */ '../../../pages/home/buyer/View'
            ),
    },
];

<template>
    <div class="header bg-light-blue pointer select-none">
        <div class="header-content page-center flex-v-center flex-between">
            <div class="flex-v-center">
                <div @click="handleHome">
                    <img
                        height="60"
                        src="../../assets/img/logo/blue.png"
                        alt=""
                    />
                </div>
                <div class="flex-v-center ml30 demand-select c-blue">
                    <div
                        class="demand-select-item flex-v-center seller"
                        :class="{ active: isSellerBidIndex }"
                        @click="handleToSellerBidIndex"
                    >
                        <Icon class="mr5" size="16" type="md-albums" />
                        待抢单需求
                    </div>
                    <!--                    <div class="demand-select-item-col bg-c-blue"></div>-->
                    <div
                        class="demand-select-item flex-v-center seller"
                        :class="{ active: isSellerIndex }"
                        @click="handleToSellerHome"
                    >
                        <Icon size="16" class="mr5" type="md-people" />
                        我的抢单
                    </div>
                </div>
            </div>

            <div class="flex-v-center c-blue">
                <div class="flex-v-center mr20" @click="handleToBuyerCenter">
                    <span class="mr5">切换为买家</span>
                    <Icon size="16" class="mr5" type="md-people" />
                </div>
                <div class="flex-v-center">
                    <Account :dark="true" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Account from './share/Account.vue';
import pageStoreMixin from '@/mixins/pageStore.mixin';

export default {
    name: 'SellerHeader',
    components: { Account },
    mixins: [pageStoreMixin],
    computed: {
        isSellerBidIndex() {
            return this.$route.path.startsWith('/home/seller/bidIndex');
        },
        isSellerIndex() {
            return this.$route.path.startsWith('/home/seller/index');
        },
    },
    mounted() {
        this.setLatestTypeSeller();
    },
    methods: {
        handleHome() {
            // /home/main/index
            if (this.$route.path !== '/home/main/index') {
                this.$router.push('/home/main/index');
            }
        },
        handleToBuyerCenter() {
            if (this.$route.path !== '/home/buyer/index') {
                this.$router.push('/home/buyer/index');
            }
        },
        handleToSellerBidIndex() {
            if (this.$route.path !== '/home/seller/bidIndex') {
                this.$router.push('/home/seller/bidIndex');
                return;
            }

            try {
                window.registerUpdateRunner();
                this.$Message.success('刷新成功');
            } catch (e) {
                // ignore
            }
        },
        handleToSellerHome() {
            if (this.$route.path !== '/home/seller/index') {
                this.$router.push('/home/seller/index');
                return;
            }

            try {
                window.registerUpdateRunner();
                this.$Message.success('刷新成功');
            } catch (e) {
                // ignore
            }
        },
    },
};
</script>
<style scoped lang="less">
@import './share/header';
</style>

const listByCondition = (params = {}) => {
    return $api.get('/ums/member/listByCondition', params);
};
const getUserInfo = (params = {}) => {
    return $api.get('/ums/member/getUserInfo', params);
};
const authCode2Session = (params = {}) => {
    return $api.get('/ums/member/authCode2Session', params);
};
const wxRegister = (params = {}) => {
    return $api.post('/ums/member/wxRegister', params);
};
const login = (params = {}) => {
    return $api.post('/ums/member/login', params);
};
const deleteFromOrg = (params = {}) => {
    return $api.post('/ums/member/deleteFromOrg', params);
};
const audit = (params = {}) => {
    return $api.post('/ums/member/audit', params);
};

const test = (params = {}) => {
    return $api.get('/temp/test/list', params);
};
const pcRegister = (params = {}) => {
    return $api.post('/ums/member/register', params);
};
const pcRegisterAndOrg = (params = {}) => {
    return $api.post('/ums/member/registerAndOrg', params);
};

const pcSendMsgRegister = (params = {}) => {
    return $api.post(
        '/tools/sendCaptchaBySms?type=' +
            params.type +
            '&phone=' +
            params.phone,
        params,
    );
};

const transferAdmin = (params = {}) => {
    return $api.post('/ums/member/transferAdmin', params);
};

const updatePassword = (params = {}) => {
    return $api.post('/ums/member/updatePassword', params);
};

const update = (params = {}) => {
    return $api.post('/ums/member/update', params);
};

export default {
    listByCondition,
    getUserInfo,
    authCode2Session,
    pcRegister,
    pcSendMsgRegister,
    wxRegister,
    login,
    test,
    deleteFromOrg,
    transferAdmin,
    updatePassword,
    pcRegisterAndOrg,
    audit,
    update,
};

<template>
    <Form
        ref="formValidate"
        :model="formValidate"
        :rules="ruleValidate"
        :label-width="120"
    >
        <FormItem label="原始密码" prop="oldPassword">
            <Input
                v-model="formValidate.oldPassword"
                clearable
                placeholder="原始密码"
            />
        </FormItem>
        <FormItem label="新密码" prop="password">
            <Input
                v-model="formValidate.password"
                clearable
                placeholder="新密码"
            />
        </FormItem>
        <FormItem label="确认新密码" prop="confirmPassword">
            <Input
                v-model="formValidate.confirmPassword"
                placeholder="确认新密码"
                clearable
            />
        </FormItem>
        <FormItem>
            <Button class="mr10" type="primary" @click="handleConfirm">
                保存
            </Button>
            <Button class="mr10" @click="handleCancel"> 取消</Button>
        </FormItem>
    </Form>
</template>
<script>
export default {
    name: 'UpdatePassword',
    data() {
        return {
            formValidate: {
                oldPassword: '',
                password: '',
                confirmPassword: '',
            },
            ruleValidate: {
                oldPassword: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                        validator: (rule, value, cb) => {
                            if (!this.formValidate.password) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                confirmPassword: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                        validator: (rule, value, cb) => {
                            if (!this.formValidate.confirmPassword) {
                                rule.message = '不能为空';
                                cb(new Error('不能为空'));
                                return;
                            }

                            if (this.formValidate.password) {
                                if (
                                    this.formValidate.password !==
                                    this.formValidate.confirmPassword
                                ) {
                                    rule.message = '两次密码输入不一致';
                                    cb(new Error('两次密码输入不一致'));
                                    return;
                                }
                            }
                            cb();
                        },
                    },
                ],
            },
        };
    },
    methods: {
        handleCancel() {
            this.$emit('close');
        },
        async handleConfirm() {
            await new Promise((resolve) =>
                this.$refs.formValidate.validate((valid) => valid && resolve()),
            );

            let res = await $service.memeber.updatePassword({
                ...this.formValidate,
            });
            this.$Message.success(res.message);

            this.handleCancel();
        },
    },
};
</script>

<template>
    <Form
        ref="formValidate"
        :model="formValidate"
        :rules="ruleValidate"
        :label-width="100"
    >
        <ICard title="企业票款信息" order="3" style="padding: 0" />
        <FormItem label="名称" prop="invoiceName">
            <Input
                v-model="formValidate.invoiceName"
                placeholder="公司名称/单位名称"
                disabled
            />
        </FormItem>
        <FormItem label="纳税人识别号" prop="invoiceSn">
            <Input
                v-model="formValidate.invoiceSn"
                placeholder="纳税人识别号"
                clearable
            />
        </FormItem>
        <FormItem label="地址" prop="invoiceAddr">
            <Input
                v-model="formValidate.invoiceAddr"
                placeholder="地址(营业执照的住所或单位地址)"
                clearable
            />
        </FormItem>
        <FormItem label="电话" prop="invoicePhone">
            <Input
                v-model="formValidate.invoicePhone"
                :maxlength="11"
                placeholder="手机机号格式1xxxxxxxxxx，座机号格式010xxxxxxxx"
                clearable
            />
        </FormItem>
        <FormItem label="开户行" prop="invoiceBank">
            <Input
                v-model="formValidate.invoiceBank"
                placeholder="开户银行名称"
                clearable
            />
        </FormItem>
        <FormItem label="账号" prop="invoiceAccount">
            <Input
                v-model="formValidate.invoiceAccount"
                placeholder="银行账号"
                clearable
            />
        </FormItem>
    </Form>
</template>

<script>
import ICard from '@/components/ICard.vue';

export default {
    components: { ICard },
    props: {
        formValidate: {},
    },
    data() {
        return {
            targetCal: dayjs(),
            timing: {
                isTiming: false,
                currentCal: dayjs(), // 倒计时
            },
            isAutoLogin: false,
            options: [],

            ruleValidate: {
                invoiceName: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                invoiceSn: [
                    {
                        required: true,
                        message: '请输入纳税人识别号',
                        trigger: 'blur',
                    },
                ],
                invoiceAddr: [
                    { required: true, message: '请输入地址', trigger: 'blur' },
                ],
                invoicePhone: [
                    {
                        required: true,
                        message: '请输入电话',
                        trigger: 'blur',
                        validator: $util.validator.phone,
                    },
                ],
                invoiceBank: [
                    {
                        required: true,
                        message: '请输入开户行',
                        trigger: 'blur',
                    },
                ],
                invoiceAccount: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                ],
            },
        };
    },
    methods: {
        async getFormData() {
            await new Promise((resolve) =>
                this.$refs.formValidate.validate((valid) => valid && resolve()),
            );
            return this.formValidate;
        },
    },
};
</script>

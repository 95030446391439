<template>
    <div style="text-align: left">
        <div>
            {{ title }}
        </div>
        <div style="max-width: 50vw">
            {{ content }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            default: '提示信息',
        },
        content: {
            default: '提示内容',
        },
    },
};
</script>

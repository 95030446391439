/**
 * {@link https://vuex.vuejs.org/zh/guide/modules.html}
 * */

// oms/need/getById
// initial state
const state = () => ({
    $needIDData: {}, // 面包屑导航
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
    set$needIDData(state, $needIDData) {
        state.$needIDData = $needIDData;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

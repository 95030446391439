let globalTimerRef = null;

const isProd = process.env.NODE_ENV === 'production';
export default class TimerUtil {
    static setTimer(fn, interval = 3e3) {
        this.clearTimer();
        if (!fn) {
            return;
        }
        if (isProd) {
            globalTimerRef = setInterval(() => fn(), interval); // TODO 先关闭 方便调试
        }
    }

    static clearTimer() {
        clearInterval(globalTimerRef);
    }
}

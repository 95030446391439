import city from './city.json';

let mapCity = new Map();
let mapPathToCode = new Map(); // p-c-r: {province, city, region}

const loopForMapCity = (children = []) => {
    for (let it of children) {
        it.text.trim();
        it.label.trim();
        mapCity.set(it.value, it);
        if (it.children) {
            loopForMapCity(it.children);
        }
    }
};

loopForMapCity(city);
const loopForMapCode = (children = [], parentCodes = []) => {
    for (let it of children) {
        if (it.children) {
            loopForMapCode(it.children, [...parentCodes, it.value]);
        } else {
            let codes = [...parentCodes, it.value];
            // final result
            let nameRet = [];
            let nameItemRet = [];
            for (let code of codes) {
                let item = mapCity.get(code);
                nameRet.push(item.text);
                nameItemRet.push(item);
            }

            mapPathToCode.set(nameRet.join('-'), {
                codes: nameItemRet.map((it) => it.value),
                items: nameItemRet,
            });
        }
    }
};

loopForMapCode(city);

export default class {
    static getItemByCode(code) {
        return mapCity.get(code);
    }

    static getCodesByNames(pathNameString) {
        return mapPathToCode.get(pathNameString);
    }
    static getCityJSON() {
        return city;
    }
}

const list = (params = {}) => {
    return $api.get('/ums/memberReceiveAddress/list', params);
};
const create = (params = {}) => {
    return $api.post('/ums/memberReceiveAddress/create', params);
};
const del = (params = {}) => {
    return $api.post('/ums/memberReceiveAddress/delete', params);
};
const update = (params = {}) => {
    return $api.post('/ums/memberReceiveAddress/update', params);
};
const getById = (params = {}) => {
    return $api.get('/ums/memberReceiveAddress/getById', params);
};

export default { list, create, update, getById, delete: del };

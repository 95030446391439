<template>
    <div class="i-card bg-white relative mb10">
        <div :id="anchor" class="i-card-anchor"></div>
        <div class="i-card-title flex">
            <div class="i-card-title-index bg-red tc c-white mr5">
                <span>{{ order }}</span>
            </div>
            <div class="i-card-title-text">
                <span>{{ title }}</span>
            </div>
        </div>
        <div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        order: {},
        title: {},
        anchor: {},
    },
};
</script>
<style lang="less" scoped>
.i-card {
    padding: 38px;
    &-anchor {
        width: 1px;
        height: 1px;
        background-color: transparent;
    }

    .i-card-title {
        &-index {
            width: 20px;
            height: 20px;
            line-height: 20px;
            border-radius: 2px;
        }
    }
}
</style>

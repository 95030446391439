// 买家
const pageByCondition = (params = {}, opts) => {
    return $api.get('/oms/need/pageByCondition', params, opts);
};
const create = (params = {}) => {
    return $api.post('/oms/need/create', params);
};
const createAndPublish = (params = {}) => {
    return $api.post('/oms/need/createAndPublish', params);
};
const modify = (params = {}) => {
    return $api.post('/oms/need/modify', params);
};
const del = (params = {}) => {
    return $api.post('/oms/need/delete', params);
};
const publish = (params = {}) => {
    return $api.post('/oms/need/publish', params);
};
const cancel = (params = {}) => {
    return $api.post('/oms/need/cancel', params);
};
const checkCancel = (params = {}) => {
    return $api.post('/oms/need/check-cancel', params);
};
const getById = (params = {}, opts) => {
    return $api.get('/oms/need/getById', params, opts);
};
// 评价详情
const getByIdDetail = (params = {}, opts) => {
    return $api.get('/oms/need/getByIdDetail', params, opts);
};
const selectSupplier = (params = {}) => {
    return $api.post('/oms/need/selectSupplier', params);
};
const extension = (params = {}) => {
    return $api.post('/oms/need/extension', params);
};

export default {
    pageByCondition,
    create,
    getById,
    delete: del,
    cancel,
    checkCancel,
    publish,
    modify,
    selectSupplier,
    extension,
    createAndPublish,
    getByIdDetail,
};

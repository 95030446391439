import amount from './amount/index';
import memeber from './memeber/index';
import notice from './notice/index';
import memberReceiveAddress from './memberReceiveAddress/index';
import needIntent from './needIntent/index';
import need from './need/index';
import org from './org/index';
import brand from './brand/index'; // 品牌
import orgBrand from './orgBrand/index'; // 品牌
import tools from './tools/index'; // 品牌
import comment from './comment/index'; // 品牌
import index from './index/index'; // 首页
import needFeedback from './needFeedback/index'; // 首页

export default {
    amount,
    memeber,
    notice,
    memberReceiveAddress,
    needIntent,
    need,
    org,
    brand,
    orgBrand,
    tools,
    comment,
    index,
    needFeedback,
};

const userIndex = [
    {
        name: '个人中心',
        path: '/home/user/index',
        style: {
            color: 'rgba(0, 191, 191, 1)',
        },
        icon: 'ios-alert',
    },
];
// 个人中心相关
export default [
    // 个人中心首页
    {
        path: 'user/index',
        meta: {
            breadcrumb: [...userIndex],
        },
        component: () =>
            import(
                /* webpackChunkName: "user-module" */ '../../../pages/home/user/Index'
            ),
    },
];

<template>
    <div v-show="!isLoginPage" class="page-bottom relative">
        <div class="page-bottom-top">
            <div class="page-center">
                <Row>
                    <Col :span="6">
                        <div class="mb20">
                            <img
                                height="44"
                                src="../assets/img/logo/footer.png"
                                alt=""
                            />
                        </div>
                        <div class="c-white h7 mb15">
                            <Icon type="ios-call" /> 138-1194-7695
                        </div>
                        <div class="c-white h7 mb15">
                            <Icon type="md-mail" /> yanxiaozhu_info@163.com
                        </div>
                    </Col>
                    <Col :span="18">
                        <Row>
                            <Col :span="8">
                                <div class="mb20 h4 c-white">使用条款</div>
                                <div class="c-white h7 mb15">网站使用条款</div>
                                <div class="c-white h7 mb15">网站免责声明</div>
                                <div class="c-white h7 mb15">隐私保护政策</div>
                            </Col>
                            <Col :span="8">
                                <div class="mb20 h4 c-white">关于研小助</div>
                                <div class="c-white h7 mb15">关于我们</div>
                                <div class="c-white h7 mb15">支持与帮助</div>
                            </Col>
                            <Col :span="8">
                                <div class="flex" style="align-items: flex-end">
                                    <div class="h4 c-white">
                                        <img
                                            height="66"
                                            src="@/assets/img/qrcode.png"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        class="ml10"
                                        style="margin-bottom: 5px"
                                    >
                                        <div class="c-white h7">联系我们</div>
                                        <div class="c-white h7">
                                            138-1194-7695
                                        </div>
                                        <div class="c-white h7">
                                            手机号同步微信
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
        <div class="page-bottom-bottom">
            <div class="page-center flex-between">
                <div>增值电信业务经营许可证:京B2-20232509</div>
                <div>ICP备案号:京ICP备2023018665号-1</div>
                <div>京公网安备 11010802025067</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageBottom',
    computed: {
        isLoginPage() {
            return this.$route.path.startsWith('/login');
        },
    },
};
</script>

<style lang="less" scoped>
.page-bottom {
    &-top {
        background: #373737;
        padding-top: 56px;
        padding-bottom: 46px;
    }

    &-bottom {
        background: #2c2c2c;
        padding: 24px 0;
    }
}
</style>

<template>
    <Form
        ref="formValidate"
        :model="formValidate"
        :rules="ruleValidate"
        :label-width="100"
    >
        <ICard title="个人信息" order="1" style="padding: 0" />
        <FormItem label="姓名" prop="name">
            <Input v-model="formValidate.name" placeholder="姓名" />
        </FormItem>
        <FormItem label="账号" prop="username">
            <Input
                v-model="formValidate.username"
                placeholder="账号"
                autocomplete="new-password"
                clearable
            />
        </FormItem>
        <FormItem label="密码" prop="password">
            <Input
                v-model="formValidate.password"
                placeholder="密码"
                type="password"
                autocomplete="new-password"
                clearable
            />
        </FormItem>
        <FormItem label="确认密码" prop="confirmPassword">
            <Input
                v-model="formValidate.confirmPassword"
                placeholder="确认密码"
                type="password"
                autocomplete="new-password"
                clearable
            />
        </FormItem>
        <FormItem label="移动电话" prop="phone">
            <Input
                v-model="formValidate.phone"
                :maxlength="11"
                placeholder="移动电话"
                clearable
            />
        </FormItem>
        <FormItem label="验证码" prop="captchaSmsCode">
            <div class="flex">
                <Input
                    v-model="formValidate.captchaSmsCode"
                    placeholder="验证码"
                    style="width: 100%"
                    clearable
                />

                <template>
                    <Button
                        v-if="timing.isTiming"
                        class="ml10"
                        type="default"
                        disabled
                    >
                        {{ countTiming }}秒后重新获取
                    </Button>
                    <Button
                        v-else
                        class="ml10"
                        type="primary"
                        :disabled="!formValidate.phone"
                        @click="sendMsg"
                    >
                        获取验证码
                    </Button>
                </template>
            </div>
        </FormItem>
        <FormItem label="默认登录身份">
            <RadioGroup v-model="formValidate.defaultIdentity">
                <Radio :label="1">买家</Radio>
                <Radio :label="2">卖家</Radio>
            </RadioGroup>
        </FormItem>
    </Form>
</template>

<script>
import ICard from '@/components/ICard.vue';
import ICity from '@/components/ICity.vue';
import IUpload from '@/components/IUpload.vue';

const TIME_COUNT = 60 * 5; // 定义60s为倒计时
export default {
    components: { IUpload, ICity, ICard },
    props: {
        formValidate: {},
    },
    data() {
        return {
            targetCal: dayjs(),
            timing: {
                isTiming: false,
                currentCal: dayjs(), // 倒计时
            },
            isAutoLogin: false,
            options: [],

            ruleValidate: {
                name: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                email: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                username: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                        validator: $util.validator.phone,
                    },
                ],
                captchaSmsCode: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                        validator: (rule, value, cb) => {
                            if (!this.formValidate.password) {
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
                confirmPassword: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                        validator: (rule, value, cb) => {
                            if (!this.formValidate.confirmPassword) {
                                rule.message = '不能为空';
                                cb(new Error('不能为空'));
                                return;
                            }

                            if (this.formValidate.password) {
                                if (
                                    this.formValidate.password !==
                                    this.formValidate.confirmPassword
                                ) {
                                    rule.message = '两次密码输入不一致';
                                    cb(new Error('两次密码输入不一致'));
                                    return;
                                }
                            }
                            cb();
                        },
                    },
                ],
            },
        };
    },
    computed: {
        countTiming() {
            let seconds = ~~(
                this.timing.currentCal.diff(this.targetCal) / 1000
            );
            if (seconds >= TIME_COUNT) {
                this.timing.isTiming = false;
                return 0;
            }
            return TIME_COUNT - seconds;
        },
    },
    methods: {
        async getFormData() {
            await new Promise((resolve) =>
                this.$refs.formValidate.validate((valid) => valid && resolve()),
            );
            return this.formValidate;
        },
        intervalTiming() {
            setInterval(() => {
                this.timing.currentCal = dayjs();
            }, 1e3);
        },
        async sendMsg() {
            try {
                let data = await $service.memeber.pcSendMsgRegister({
                    type: this.formValidate.type,
                    phone: this.formValidate.phone,
                });
                if (data.code === 100000) {
                    await this.$toast.success('提示', '发送成功');
                    this.targetCal = dayjs();
                    this.timing.isTiming = true;
                    this.timing.currentCal = dayjs();
                    this.intervalTiming();
                }
            } catch (e) {
                let data = e.data || {};
                if (data.code !== 100000) {
                    await this.$toast.error('提示', e.data.message);
                }
            }
        },
    },
};
</script>

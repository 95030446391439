/**
 * {@link https://vuex.vuejs.org/zh/guide/modules.html}
 * */

// initial state
const state = () => ({
    breadcrumb: [], // 面包屑导航
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
    setBreadcrumb(state, breadcrumb) {
        state.breadcrumb = breadcrumb;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

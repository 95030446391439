// 买家
const getInfo5 = (params = {}) => {
    return $api.get('/index/info5');
};
const getInfo15 = (params = {}) => {
    return $api.get('/index/info15');
};

export default {
    getInfo5,
    getInfo15,
};

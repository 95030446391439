<template>
    <div class="account-login">
        <Form
            ref="formValidate"
            :model="formValidate"
            :rules="ruleValidate"
            :label-width="0"
        >
            <FormItem label="" prop="username">
                <Input
                    v-model="formValidate.username"
                    prefix="ios-person-outline"
                    autocomplete="new-password"
                    placeholder="账户"
                    clearable
                />
            </FormItem>
            <FormItem label="" prop="password">
                <Input
                    v-model="formValidate.password"
                    prefix="ios-lock-outline"
                    autocomplete="new-password"
                    type="password"
                    placeholder="密码"
                    clearable
                />
            </FormItem>
        </Form>

        <!--        <div class="account-login-item flex-between">-->
        <!--            <div>-->
        <!--                <Checkbox v-model="isAutoLogin">自动登录</Checkbox>-->
        <!--            </div>-->
        <!--            &lt;!&ndash;            <span class="c-red pointer">忘记密码</span>&ndash;&gt;-->
        <!--        </div>-->
        <div class="account-login-item flex-between">
            <Button
                type="primary"
                long
                class="login-action"
                style="height: 40px"
                :disabled="isDisabledLogin"
                @click="login"
                >登录
            </Button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isAutoLogin: false,
            formValidate: {
                username: '',
                password: '',
            },
            ruleValidate: {
                username: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    computed: {
        isDisabledLogin() {
            return !this.formValidate.password || !this.formValidate.username;
        },
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleLogin);
    },
    mounted() {
        window.addEventListener('keydown', this.handleLogin);
    },
    methods: {
        async login() {
            await new Promise((resolve) =>
                this.$refs.formValidate.validate((valid) => valid && resolve()),
            );

            let data = await $service.memeber.login({
                username: this.formValidate.username,
                password: this.formValidate.password,
            });

            let token = data.data.token;
            $util.setToken(token);
            if (data.code === 100000) {
                if (data.data.defaultIdentity === 1) {
                    await this.$router.push('/home/buyer/index');
                }
                if (data.data.defaultIdentity === 2) {
                    await this.$router.push('/home/seller/index');
                }
            }
        },
        handleLogin(e) {
            if (this.isDisabledLogin) {
                return;
            }
            if (e.key === 'Enter' || e.code === 'Enter') {
                this.login();
            }
        },
    },
};
</script>
<style lang="less" scoped>
.account-login {
    .account-login-item {
        margin-bottom: 24px;
    }
}
</style>

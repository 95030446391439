import Index from '../../pages/home/Index.vue';
import user from './user';
import buyer from './buyer';
import seller from './seller';
import main from './main';

export default [
    {
        path: '/home',
        component: Index,
        children: [
            ...user, // 个人中心组
            ...buyer, // 买家组
            ...seller, // 卖家组
            ...main, // 卖家组
        ],
    },
];

// 卖家
const pageBySupplier = (params = {}, opts) => {
    return $api.get('/oms/needIntent/pageBySupplier', params, opts);
};

const bid = (params = {}) => {
    return $api.post('/oms/needIntent/bid', params);
};

const getIntentByNeedId = (params = {}, opts) => {
    return $api.get('/oms/needIntent/getIntentByNeedId', params, opts);
};

const cancel = (params = {}) => {
    return $api.post('/oms/needIntent/cancel', params);
};

export default { pageBySupplier, bid, getIntentByNeedId, cancel };

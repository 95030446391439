import TimerUtil from './timerUtil';
import validatorUtl from '@/util/validator.utl';
import dayjs from 'dayjs';

const TokenKey = 'Admin-Token';
let _seed = 1;
const _pageTime = Date.now();

export default class {
    static timerUtil = TimerUtil;
    static validator = validatorUtl;
    static setToken(token) {
        return localStorage.setItem(TokenKey, token);
    }
    static clearToken() {
        return this.setToken('');
    }
    static getToken() {
        return localStorage.getItem(TokenKey);
    }

    static getUniqId() {
        return `${_pageTime}_${_seed++}`;
    }

    static getOptName = (
        value,
        options = [],
        { prop = 'value', text = 'text' } = {},
    ) => {
        for (let it of options) {
            if (it[prop] === value || it[prop] - value === 0) {
                return it[text];
            }
        }
    };

    static format$Date = (time) => {
        return dayjs(time).format('YYYY-MM-DD');
    };
}

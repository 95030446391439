<template>
    <Form
        ref="formValidate"
        :model="formValidate"
        :rules="ruleValidate"
        :label-width="100"
    >
        <ICard title="企业基础信息" order="2" style="padding: 0" />
        <FormItem label="企业名称" prop="modelOrgName">
            <OrgNameSelector
                ref="orgNameSelector"
                v-model="formValidate.modelOrgName"
            />
        </FormItem>
        <FormItem label="所在城市" prop="modelOrgCityids">
            <ICity
                ref="icity"
                v-model="formValidate.modelOrgCityids"
                class="w400"
                :disabled="disabledModelOrgName"
            />
        </FormItem>
        <FormItem label="营业执照" prop="modelOrgBusinessLicense">
            <IUpload
                v-model="formValidate.modelOrgBusinessLicense"
                :disabled="disabledModelOrgName"
                @ocrData="handleOcrData"
            />
        </FormItem>
    </Form>
</template>

<script>
import ICard from '@/components/ICard.vue';
import ICity from '@/components/ICity.vue';
// import IUpload from '@/components/IUpload.vue';
import IUpload from '@/components/IUploadCert.vue';
import OrgNameSelector from '@/components/OrgNameSelector.vue';
import UserForm from '@/pages/login/component/AccountRegister/UserForm.vue';

export default {
    components: { UserForm, OrgNameSelector, IUpload, ICity, ICard },
    props: {
        formValidate: {},
    },
    data() {
        window.txcom = this;
        return {
            options: [],

            ruleValidate: {
                modelOrgName: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                        validator: (rule, value, cb) => {
                            setTimeout(() => {
                                if (!this.formValidate.modelOrgName) {
                                    rule.message = '不能为空';
                                    cb(new Error('不能为空'));
                                    return;
                                }

                                cb();
                            }, 1000);
                        },
                    },
                ],
                modelOrgBusinessLicense: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                modelOrgCityids: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                        validator: (rule, value, cb) => {
                            if (value.length !== 3) {
                                rule.message = '不能为空';
                                cb(new Error('不能为空'));
                                return;
                            }
                            cb();
                        },
                    },
                ],
            },
        };
    },
    computed: {
        disabledModelOrgName() {
            return typeof this.formValidate.modelOrgName === typeof 0;
        },
    },
    watch: {
        'formValidate.modelOrgName'() {
            this.changeOnSelect();
        },
    },
    methods: {
        handleOcrData(ocrData) {
            let {
                name, // 名称
            } = _.get(ocrData, 'data') || {};
            this.$refs.orgNameSelector.handleSelectSearchValue(name);
            this.$emit('ocrData', ocrData);

            this.$nextTick(() => {
                this.$refs.formValidate.validateField('modelOrgName');
            });
        },
        getOrgRealName() {
            try {
                if (this.disabledModelOrgName) {
                    let m = this.getOptionsMap();
                    let s = m[this.formValidate.modelOrgName];
                    return _.get(s, 'label');
                }
            } catch (e) {}

            return this.formValidate.modelOrgName;
        },
        async getFormData() {
            await new Promise((resolve) =>
                this.$refs.formValidate.validate((valid) => valid && resolve()),
            );
            return this.formValidate;
        },
        getOptionsMap() {
            return this.$refs.orgNameSelector.options.reduce((t, c) => {
                return {
                    ...t,
                    [c.value]: c,
                };
            }, {});
        },
        changeOnSelect() {
            if (!this.disabledModelOrgName) {
                this.formValidate.modelOrgCityids = [];
                this.formValidate.modelOrgBusinessLicense = '';
                this.$nextTick(() => {
                    this.$refs.icity.updatePropValue();
                });
                return;
            }

            let optionsMap = this.getOptionsMap();

            let modelSelectedItem = optionsMap[this.formValidate.modelOrgName];

            let cityids = [
                modelSelectedItem.item.province,
                modelSelectedItem.item.city,
                modelSelectedItem.item.region,
            ];

            // 城市地址有问题默认空
            if (cityids.filter((it) => typeof it === typeof 0).length !== 3) {
                cityids = [];
            }
            this.formValidate.modelOrgCityids = cityids;
            this.formValidate.modelOrgBusinessLicense =
                modelSelectedItem.item.businessLicense;
            this.$nextTick(() => {
                this.$refs.icity.updatePropValue();
            });
        },
    },
};
</script>

import { createNamespacedHelpers } from 'vuex';
import userInfoMixin from '@/mixins/userInfo.mixin';

const { mapState, mapMutations } = createNamespacedHelpers('currentOrg');

let sc = {
    0: {
        style: { color: '#0a5495' },
        text: '认证中',
        img: require('@/assets/img/approve/process.png'),
    },
    1: {
        style: { color: '#00c395' },
        text: '认证通过',
        img: require('@/assets/img/approve/ok.png'),
    },
    2: {
        style: { color: '#999999' },
        text: '认证未通过',
        img: require('@/assets/img/approve/fail.png'),
    },
};
export default {
    mixins: [userInfoMixin],
    computed: {
        ...mapState(['$currentOrg']),
        isAdmin() {
            let isadmin = _.get(this.$currentOrg, 'isAdmin');
            if (isadmin) {
                return !!_.get(this.$currentOrg, 'isAdmin');
            }
        },
        statusApproved() {
            let status = _.get(this.$currentOrg, 'org.status');
            if (status) {
                return sc[status];
            }
        },
        orgType() {
            let type = _.get(this.$currentOrg, 'org.type');
            if (type) {
                return $util.getOptName(type, $const.options.orgType);
            }
        },
    },
    methods: {
        ...mapMutations(['set$currentOrg']),
        async get$currentOrg(params = {}) {
            await this.$nextTick();
            let res = await $service.org.getCurrentOrg(params);
            let data = _.get(res, 'data') || {};
            this.set$currentOrg(data);
            return data;
        },
        handleToast() {
            let status = _.get(this.$currentOrg, 'org.status');
            if (status - 2 === 0) {
                let content =
                    _.get(this.$currentOrg, 'org.rejectMark') ||
                    this.$emptyContent;
                this.$toast.warning('资质认证失败原因', content, {
                    duration: 0,
                    closable: true,
                });
            }
        },
    },
};

<template>
    <div>
        <Modal
            v-model="updatePasswordModel"
            title="修改密码"
            :closable="false"
            :footer-hide="true"
        >
            <UpdatePassword
                v-if="updatePasswordModel"
                @close="updatePasswordModel = false"
            />
        </Modal>
        <Dropdown @on-click="handleDrop">
            <div class="pointer" :class="displayClass">
                <span v-if="hasLogin" class="mr5"
                    >我的账号({{ $userInfo.username }})</span
                >
                <span v-else class="mr5" @click.stop="handleLogin">登录</span>

                <Icon v-if="hasLogin" size="20" type="md-arrow-dropdown" />
            </div>
            <template v-if="hasLogin" slot="list">
                <DropdownMenu>
                    <DropdownItem name="userCenter"> 个人中心 </DropdownItem>
                    <DropdownItem name="updatePassword">
                        修改密码
                    </DropdownItem>
                    <DropdownItem name="logout"> 退出登录 </DropdownItem>
                </DropdownMenu>
            </template>
        </Dropdown>
    </div>
</template>
<script>
import userInfoMixin from '@/mixins/userInfo.mixin';
import currentOrgMixin from '@/mixins/currentOrg.mixin';
import UpdatePassword from '@/pages/home/user/Index/Personal/UpdatePassword.vue';

export default {
    components: { UpdatePassword },
    mixins: [userInfoMixin, currentOrgMixin],
    props: {
        dark: {},
    },
    data() {
        return {
            updatePasswordModel: false,
        };
    },
    computed: {
        displayClass() {
            return this.dark ? 'c-blue' : 'c-white';
        },
        hasLogin() {
            return this.$userInfo.id;
        },
    },
    created() {
        this.get$userInfo();
        this.get$currentOrg();
    },
    methods: {
        handleDrop(type) {
            if (type === 'logout') {
                this.loginOut();
                this.clearUserInfo();
                return;
            }
            if (type === 'updatePassword') {
                this.updatePasswordModel = true;
                return;
            }

            if (type === 'userCenter') {
                if (this.$route.path !== '/home/user/index') {
                    this.$router.push('/home/user/index');
                }
            }
        },
        handleLogin() {
            if (this.$route.path !== '/login') {
                this.$router.push('/login');
            }
        },
        loginOut() {
            $util.clearToken();
            if (this.$route.path !== '/login') {
                this.$router.push('/login');
            }
        },
    },
};
</script>

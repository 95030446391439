<template>
    <div class="account-login">
        <Row :gutter="24">
            <Col :span="12">
                <UserForm ref="userForm" :form-validate="userForm" />
                <div class="account-login-item flex-v-center">
                    <Button
                        type="primary"
                        size="small"
                        class="mr10"
                        @click="register"
                    >
                        <span v-if="disabledModelOrgName">注册并加入企业</span>
                        <span v-else>注册</span>
                    </Button>
                    <div class="c-red pointer" @click="useAccount">
                        使用已有账号登录
                    </div>
                </div>
            </Col>

            <Col :span="12">
                <CompanyForm
                    ref="companyForm"
                    :form-validate="companyForm"
                    @ocrData="handleOcrData"
                />
                <ReceiptForm
                    v-if="companyForm.modelOrgName && !disabledModelOrgName"
                    ref="receiptForm"
                    :form-validate="receiptForm"
                />
                <!--                <ReceiptForm :formValidate="receiptForm" ref="receiptForm" :disabled = "!!companyForm.modelOrgName && !disabledModelOrgName "/>-->
            </Col>
        </Row>
    </div>
</template>

<script>
import UserForm from '@/pages/login/component/AccountRegister/UserForm.vue';
import CompanyForm from '@/pages/login/component/AccountRegister/CompanyForm.vue';
import ReceiptForm from '@/pages/login/component/AccountRegister/ReceiptForm.vue';

export default {
    components: {
        ReceiptForm,
        CompanyForm,
        UserForm,
    },
    data() {
        return {
            userForm: {
                invoiceShow: true,
                defaultIdentity: 1,
                name: '',
                password: '',
                confirmPassword: '',

                username: '',
                phone: '',
                captchaSmsCode: '',
                type: '1',
            },

            companyForm: {
                // 企业基础信息
                modelOrgName: '',
                modelOrgBusinessLicense: '',
                modelOrgCityids: [],
            },

            receiptForm: {
                invoiceName: '',
                invoiceSn: '',
                invoiceAddr: '',
                invoicePhone: '',
                invoiceBank: '',
                invoiceAccount: '',
            },
        };
    },
    computed: {
        disabledModelOrgName() {
            window.tx = this;
            return typeof this.companyForm.modelOrgName === typeof 0;
        },
    },
    watch: {
        'companyForm.modelOrgName'(value) {
            this.receiptForm.invoiceName = value;
        },
    },

    methods: {
        useAccount() {
            this.$emit('useAccount');
        },
        handleOcrData(ocrData) {
            console.log('ocrData', ocrData);
            let {
                name, // 名称
                registerNumber, // 税号
                address, // 税号
            } = _.get(ocrData, 'data') || {};
            this.receiptForm.invoiceName = name;
            this.receiptForm.invoiceSn = registerNumber;
            this.receiptForm.invoiceAddr = address;
        },

        async register() {
            await Promise.all(
                [
                    this.$refs.userForm && this.$refs.userForm.getFormData(),
                    this.$refs.companyForm &&
                        this.$refs.companyForm.getFormData(),
                    this.$refs.receiptForm &&
                        this.$refs.receiptForm.getFormData(),
                ].filter(Boolean),
            ); // 校验

            let modelParams = {
                // id,
                // name: modelSelectedItem.name,
                businessLicense: this.companyForm.modelOrgBusinessLicense,
                province: this.companyForm.modelOrgCityids[0],
                city: this.companyForm.modelOrgCityids[1],
                region: this.companyForm.modelOrgCityids[2],
            };
            if (this.disabledModelOrgName) {
                modelParams.id = this.companyForm.modelOrgName;
            } else {
                modelParams.name = this.companyForm.modelOrgName;
            }

            let params = {
                ...this.userForm,
                modelParams,
            };

            if (!this.disabledModelOrgName) {
                params.receiptParams = this.receiptForm;
            }

            let data = await $service.memeber.pcRegisterAndOrg(params);

            if (data.code === 100000) {
                if (this.disabledModelOrgName) {
                    this.$toast.success(
                        '提交成功',
                        '欢迎入住! 您的企业资料被审核通过后即可发单和抢单, 工作时间15分钟内完成审核, 请稍等',
                    );
                } else {
                    let name = this.$refs.companyForm.getOrgRealName();
                    this.$toast.success(
                        '提交成功',
                        `欢迎入住! 您的资料被${name}公司管理人员通过后即可发单和抢单`,
                        {
                            duration: 5,
                            onClose: async () => {
                                // 注册后直接登录
                                let data = await $service.memeber.login({
                                    username: params.username,
                                    password: params.password,
                                });

                                let token = data.data.token;
                                $util.setToken(token);
                                if (data.code === 100000) {
                                    let isBuyer =
                                        params.defaultIdentity - 1 === 0;
                                    if (isBuyer) {
                                        await this.$router.push(
                                            '/home/buyer/index',
                                        );
                                    } else {
                                        await this.$router.push(
                                            '/home/seller/index',
                                        );
                                    }
                                }
                                // this.UseAccount();
                            },
                        },
                    );
                }
            } else {
                await this.$Message.error(data.msg);
            }
        },
    },
};
</script>
<style lang="less" scoped>
.account-login {
    .account-login-item {
        margin-bottom: 24px;
    }

    .login-action {
        &:hover {
            background: #de1e32;
            color: white;
        }
    }
}
</style>

/**
 * {@link https://vuex.vuejs.org/zh/guide/modules.html}
 * */

// oms/need/getById
// initial state
const state = () => ({
    $currentOrg: {}, // currentOrg
});

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
    set$currentOrg(state, $currentOrg) {
        state.$currentOrg = $currentOrg;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

import options from './options';

const needStatus = {
    1: {
        display: '待发布',
        value: 1,
        color: '#0a5495',
    },
    2: {
        display: '待抢单',
        value: 2,
        color: '#00c395',
    },
    3: {
        display: '待选择',
        value: 3,
        color: '#e54545',
    },
    4: {
        display: '待评价',
        value: 4,
        color: '#fea834',
    },
    5: {
        display: '已完成',
        value: 5,
        color: '#00c395',
    },
    56: {
        display: '已结束',
        value: 56,
        color: '#00c395',
    },
    6: {
        display: '取消',
        value: 6,
        color: '#999999',
    },
    7: {
        display: '流标',
        value: 7,
        color: '#999999',
    },
    8: {
        display: '已履约',
        value: 8,
        color: '#00c395',
    },
    9: {
        display: '超时取消',
        value: 9,
        color: '#999999',
    },
};

const needIntentStatus = {
    1: {
        display: '已投标',
        value: 1,
        color: 'green',
    },
    2: {
        display: '已报价',
        value: 2,
        color: '#f2826a',
    },
    3: {
        display: '买家取消',
        value: 3,
        color: 'blue',
    },
    4: {
        display: '未中标',
        value: 4,
        color: '#db781a',
    },
    5: {
        display: '已中标',
        value: 5,
        color: 'purple',
    },
    6: {
        display: '需求单删除',
        value: 6,
        color: 'red',
    },
    7: {
        display: '卖家取消',
        value: 7,
        color: 'pink',
    },
};

const needTab = {
    1: {
        display: '全部',
        value: 1,
        color: '#f2826a',
    },
    2: {
        display: '抢单中',
        value: 2,
        color: '#f2826a',
    },
    3: {
        display: '已报价',
        value: 3,
        color: 'blue',
    },
    4: {
        display: '已中选',
        value: 4,
        color: 'yellow',
    },
    5: {
        display: '待发布',
        value: 5,
        color: 'purple',
    },
    // 6: {
    //     display: '待抢单',
    //     value: 6,
    //     color: 'red',
    // },
    7: {
        display: '待选取商家',
        value: 7,
        color: 'pink',
    },
    8: {
        display: '待评价',
        value: 8,
        color: 'pink',
    },
    9: {
        display: '未中选',
        value: 9,
        color: 'pink',
    },
};
const memberType = {
    0: {
        display: '普通用户',
        value: 0,
        color: '#f2826a',
    },
    1: {
        display: '普通用户',
        value: 1,
        color: '#f2826a',
    },
    2: {
        display: '管理员',
        value: 2,
        color: '#f2826a',
    },
    3: {
        display: '普通用户',
        value: 3,
        color: 'blue',
    },
};
const memberStatus = {
    0: {
        display: '未授权',
        value: 0,
        color: '#f2826a',
    },
    1: {
        display: '已授权',
        value: 1,
        color: '#00c395',
    },
    2: {
        display: '已拒绝',
        value: 2,
        color: '#f2826a',
    },
};
const showStatus = {
    0: {
        display: '待审核',
        value: 0,
        color: '#f2826a',
    },
    1: {
        display: '审核通过',
        value: 1,
        color: '#f2826a',
    },
    2: {
        display: '审核不通过',
        value: 2,
        color: 'blue',
    },
    3: {
        display: '未申请',
        value: 3,
        color: 'blue',
    },
    4: {
        display: '已过期',
        value: 4,
        color: 'blue',
    },
};

const qualification = {
    0: {
        display: '',
        value: 0,
        color: '#f2826a',
    },
    1: {
        display: '生产厂家',
        value: 1,
        color: '#f2826a',
    },
    2: {
        display: '一级代理',
        value: 2,
        color: '#f2826a',
    },
    3: {
        display: '经销商',
        value: 3,
        color: 'blue',
    },
    4: {
        display: '其他',
        value: 3,
        color: 'blue',
    },
    5: {
        display: '全部',
        value: 3,
        color: 'blue',
    },
};
export default {
    needStatus, // 需求单状态
    needIntentStatus, // 卖家抢单状态
    needTab, // 需求tab标签
    showStatus, //审批状态
    memberType, //会员角色
    memberStatus, //会员状态
    qualification, //代理资质
    ...options, // 本地选项
    role: {
        // 1：普通用户，2：机构管理员，3：机构普通用户
        1: '普通用户',
        2: '机构管理员',
        3: '机构普通用户',
    },
};

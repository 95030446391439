import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import { LoadingBar, Message } from 'view-design';

const isProd = process.env.NODE_ENV === 'production';

let instance = axios.create({
    timeout: 1000 * 120,
    withCredentials: false,
    baseURL: process.env.VUE_APP_BASE_API,
});

instance.interceptors.request.use(
    (config) => {
        config.headers['token'] = $util.getToken();
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

const shouldStatic = ['/login', '/', '/home/main/index'];
// 响应拦截器
instance.interceptors.response.use(
    (response) => {
        let code = _.get(response, 'data.code');

        if (code - 100402 === 0 || code - 1 === 0) {
            if (!shouldStatic.includes(getPath())) {
                location.href = '#/login';
            }
            return Promise.reject(response);
        }

        if (code - 100000 !== 0) {
            return Promise.reject(response);
        }

        return response;
    },
    (error) => {
        return Promise.reject(error);
    },
);

const logOk = (method, url, ...rest) => {
    if (isProd) return;
    let formatText = `%c 👍🏻👍🏻 ${method}::${url} $ `;
    console.log(formatText, 'background: green;color: white;', ...rest);
};

const logError = (method, url, ...rest) => {
    if (isProd) return;
    let formatText = `%c 🐛🐛 ${method}::${url} $ `;
    console.log(formatText, 'background: red;color: white;', ...rest);
};

const deferMessage = _.debounce((msg, shouldTip = true) => {
    if (shouldTip) {
        Message.error(msg || '报错!');
    }
}, 2e2);

const loginPageTipApi = [
    '/ums/member/login',
    '/ums/member/register',
    '/ums/member/registerAndOrg',
    '/tools/sendCaptchaBySms',
];
const loginPath = '/login';
const mainPath = '/home/main/index';
async function internalApi(config) {
    let time = Date.now();
    let routePath = getPath();
    let silent = config.silent;

    let res;
    let showLoading = !config.hideLoading;
    if (silent) {
        showLoading = false;
    }

    try {
        showLoading && LoadingBar.start();
        res = await instance(config);
    } catch (e) {
        showLoading && LoadingBar.error();
        let code = _.get(e, 'data.code');
        if (code - 1 === 0) {
            silent = true;
        }

        let hasToken = $util.getToken();
        if (!hasToken && loginPageTipApi.includes(config.url)) {
            silent = false;
        }

        let loginPage = routePath === loginPath;
        if (silent) {
            deferMessage(_.get(e, 'data.message'), false);
        } else if (loginPage) {
            deferMessage(
                _.get(e, 'data.message'),
                loginPageTipApi.includes(config.url),
            );
        } else if (routePath === mainPath) {
            deferMessage(_.get(e, 'data.message'), false);
        } else {
            deferMessage(_.get(e, 'data.message'));
        }

        logError(config.method, config.url, {
            data: config.data || config.params || '@@',
            response: e.data,
            timing: Date.now() - time,
        });

        return Promise.reject(e);
    }
    showLoading && LoadingBar.finish();

    logOk(config.method, config.url, {
        data: config.data || config.params || '@@',
        response: res.data,
        timing: Date.now() - time,
    });

    return res.data;
}

/**
 * $api 具体实现方法
 * */
class API {
    static get(url, params, options = {}) {
        return internalApi({ url, method: 'get', params, ...options });
    }

    static post(url, data, options = {}) {
        return internalApi({ url, method: 'post', data, ...options });
    }

    static postFormJSON(url, data, options = {}) {
        data = qs.stringify(data);
        return internalApi({ url, method: 'post', data, ...options });
    }
}

function getPath() {
    let href = window.location.href;
    const index = href.indexOf('#');
    // empty path
    if (index < 0) return '';

    href = href.slice(index + 1);

    let searchIdx = href.indexOf('?');
    if (searchIdx >= 0) {
        href = href.slice(0, searchIdx);
    }

    return href;
}

export default API;

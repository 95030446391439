import Vue from 'vue';
import ViewUI from 'view-design';
import App from './pages/App.vue';
import store from './store'; // 全局store
import 'view-design/src/styles/index.less';
import './styles/index.less';
import router from './router';
import _ from 'lodash';
import dayjs from 'dayjs';

import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import ToastMessage from '@/components/ToastMessage.vue';

Vue.use(Viewer);

// import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { Icon } from 'ant-design-vue';
// Vue.use(Antd); // - 逐步替换Antd 为vue3准备
Vue.component(Icon.name, Icon); // - 逐步替换Antd 为vue3准备

Vue.use(ViewUI); // 使用view-design-ui

Vue.config.productionTip = false;

// 全局
Vue.prototype.get$needItem = (needItemListObj, key, defaultValue = '') => {
    return _.get(needItemListObj, `needItemList.0.${key}`) || defaultValue;
};

Vue.prototype.$emptyContent = process.env.VUE_APP_EMPTY_CONTENT;
Vue.prototype.$preview = function (url = []) {
    let images = [];
    if (Array.isArray(url)) {
        images = url;
    } else if (typeof url === typeof '') {
        images = [url];
    }

    if (!images.length) {
        return;
    }

    this.$viewerApi({
        images,
    });
};

Vue.prototype.$toast = {
    _type(type, title, content, opts = {}) {
        let props = {
            title,
            content,
        };
        Vue.prototype.$Message[type]({
            render: (h) => h(ToastMessage, { props }),
            ...opts,
        });
    },
    success(title, content, opts) {
        this._type('success', title, content, opts);
    },
    error(title, content, opts) {
        this._type('error', title, content, opts);
    },
    warning(title, content, opts) {
        this._type('warning', title, content, opts);
    },
    info(title, content, opts) {
        this._type('info', title, content, opts);
    },
};

window._ = _;
window.dayjs = dayjs;
if (process.env.NODE_ENV === 'development') {
    Object.assign(window, { $util, $api, $const, $service });
}

const appRoot = new Vue({
    router,
    store,
    render: (h) => h(App),
});

appRoot.$mount('#app');

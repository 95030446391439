<template>
    <div>
        <UserHeader v-if="$userInfo.username" />
        <div v-else class="header bg-red pointer select-none">
            <div class="header-content page-center flex-v-center flex-between">
                <div class="flex-v-center">
                    <div @click="handleHome">
                        <img
                            height="60"
                            src="../../assets/img/logo/white.png"
                            alt=""
                        />
                    </div>
                </div>

                <div class="flex-v-center c-white">
                    <div class="flex-v-center">
                        <Account />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Account from '@/components/header/share/Account.vue';
import userInfoMixin from '@/mixins/userInfo.mixin';
import UserHeader from '@/components/header/UserHeader.vue';

export default {
    name: 'MainHeader',
    components: { UserHeader, Account },
    mixins: [userInfoMixin],
    computed: {
        loadingStyle() {
            return {
                opacity: this.$userInfo.id ? 1 : 0,
            };
        },
    },
    methods: {
        handleHome() {
            // /home/main/index
            if (this.$route.path !== '/home/main/index') {
                this.$router.push('/home/main/index');
            }
        },
    },
};
</script>
<style scoped lang="less">
@import './share/header';
</style>

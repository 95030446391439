<template>
    <div class="account-login">
        <Form
            ref="formValidate"
            :model="formValidate"
            :rules="ruleValidate"
            :label-width="0"
        >
            <FormItem label="" prop="phone">
                <Input
                    v-model="formValidate.phone"
                    prefix="ios-call-outline"
                    placeholder="手机号"
                    :maxlength="11"
                    style="width: 100%"
                    clearable
                />
            </FormItem>
            <FormItem label="" prop="smsCode">
                <div class="flex">
                    <Input
                        v-model="formValidate.smsCode"
                        prefix="ios-mail-outline"
                        placeholder="验证码"
                        style="width: 100%"
                        clearable
                    />
                    <!--                    <Button-->
                    <!--                        class="ml10"-->
                    <!--                        type="default"-->
                    <!--                        :disabled="!formValidate.phone"-->
                    <!--                        @click="getSmsCode"-->
                    <!--                    >-->
                    <!--                        验证码-->
                    <!--                    </Button>-->

                    <template>
                        <Button
                            v-if="timing.isTiming"
                            class="ml10"
                            type="default"
                            disabled
                        >
                            {{ countTiming }}秒后重新获取
                        </Button>
                        <Button
                            v-else
                            class="ml10"
                            type="primary"
                            :disabled="!formValidate.phone"
                            @click="getSmsCode"
                        >
                            获取验证码
                        </Button>
                    </template>
                </div>
            </FormItem>
        </Form>

        <!--        <div class="account-login-item flex-between">-->
        <!--            <div>-->
        <!--                <Checkbox v-model="isAutoLogin">自动登录</Checkbox>-->
        <!--            </div>-->
        <!--            &lt;!&ndash;            <span class="c-red pointer">忘记密码</span>&ndash;&gt;-->
        <!--        </div>-->
        <div class="account-login-item flex-between">
            <Button
                long
                type="primary"
                style="height: 40px"
                :disabled="isDisabledLogin"
                @click="login"
            >
                登录
            </Button>
        </div>
    </div>
</template>

<script>
const TIME_COUNT = 60; // 定义60s为倒计时
export default {
    data() {
        return {
            targetCal: dayjs(),
            timing: {
                isTiming: false,
                currentCal: dayjs(), // 倒计时
            },
            isAutoLogin: false,
            type: '1',
            formValidate: {
                phone: '',
                smsCode: '',
            },
            ruleValidate: {
                phone: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
                smsCode: [
                    {
                        required: true,
                        message: '不能为空',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    computed: {
        isDisabledLogin() {
            return !this.formValidate.phone || !this.formValidate.smsCode;
        },
        countTiming() {
            let seconds = ~~(
                this.timing.currentCal.diff(this.targetCal) / 1000
            );
            if (seconds >= TIME_COUNT) {
                this.timing.isTiming = false;
                return 0;
            }
            return TIME_COUNT - seconds;
        },
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleLogin);
    },
    mounted() {
        window.addEventListener('keydown', this.handleLogin);
    },
    methods: {
        async getSmsCode() {
            let res = await $service.tools.sendCaptchaBySms({
                phone: this.formValidate.phone,
                // type: this.type,
                type: 2,
            });

            if (res.code !== 100000) {
                this.$Message.error(res.data.message);
                return;
            }

            this.targetCal = dayjs();
            this.timing.isTiming = true;
            this.timing.currentCal = dayjs();
            this.intervalTiming();
        },
        async login() {
            await new Promise((resolve) =>
                this.$refs.formValidate.validate((valid) => valid && resolve()),
            );

            let loginRes = await $service.memeber.login({
                phone: this.formValidate.phone,
                captchaSmsCode: this.formValidate.smsCode,
            });
            let token = loginRes.data.token;
            $util.setToken(token);
            if (loginRes.code === 100000) {
                await this.$router.push('/home/buyer/index');
            }
        },
        handleLogin(e) {
            if (this.isDisabledLogin) {
                return;
            }
            if (e.key === 'Enter' || e.code === 'Enter') {
                this.login();
            }
        },
        intervalTiming() {
            setInterval(() => {
                this.timing.currentCal = dayjs();
            }, 1e3);
        },
    },
};
</script>
<style lang="less" scoped>
.account-login {
    .account-login-item {
        margin-bottom: 24px;

        .login-action {
            &:hover {
                background: #de1e32;
                color: white;
            }
        }
    }
}
</style>

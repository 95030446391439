export default {
    phone: (rule, value, cb) => {
        if (!value) {
            rule.message = '手机号不能为空';
            cb(new Error(rule.message));
            return;
        }

        if (!+value || value.length !== 11) {
            rule.message = '手机号格式不正确';
            cb(new Error(rule.message));
            return;
        }

        cb();
    },
};

<template>
    <div class="i-upload-scoped">
        <Upload
            action="/api/tools/upload"
            :show-upload-list="false"
            accept="image/*"
            :on-success="handleSuccess"
            :disabled="disabled"
        >
            <div v-if="value" class="flex-v-center">
                <div class="relative flex preview-wrap pointer">
                    <div style="height: 50px" class="flex-v-center">
                        <img
                            :src="value"
                            :alt="value"
                            width="50"
                            style="max-height: 50px"
                        />
                    </div>

                    <span class="absolute ipreview" style="">
                        <Icon
                            size="20"
                            type="ios-eye-outline"
                            @click.stop.prevent="$preview(value)"
                        />
                        <Icon size="20" type="ios-cloud-upload-outline" />
                    </span>
                    <span
                        class="absolute ipreview-close"
                        @click.stop.prevent="handleDel"
                    >
                        x
                    </span>
                </div>
            </div>
            <span v-if="!value" class="c-blue pointer">上传</span>
        </Upload>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        value: {},
        disabled: {
            default: false,
        },
    },
    methods: {
        handleDel() {
            console.log('删除图片的参数=====' + this.disabled);
            if (this.disabled) {
                this.$Message.error('当前图片不可删除');
                return;
            }
            this.handleSuccess({ e: {} });
        },
        handleSuccess(e) {
            this.$emit('change', e.data);
        },
    },
};
</script>

<style lang="less">
.i-upload-scoped {
    .preview-wrap {
        .ipreview {
            display: none;
            align-items: center;
            justify-content: center;
            color: white;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #0000009e;
        }

        .ipreview-close {
            display: none;
            width: 15px;
            height: 15px;
            font-size: 15px;
            color: white;
            top: 0;
            right: 0;
            justify-content: center;
            align-items: center;
        }

        &:hover {
            .ipreview {
                display: flex;
            }

            .ipreview-close {
                display: flex;
            }
        }
    }
}
</style>

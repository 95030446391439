const mainIndex = [
    {
        name: '首页',
        path: '/home/main/index',
        style: {
            color: 'rgba(0, 191, 191, 1)',
        },
        icon: 'ios-alert',
    },
];
// 首页相关
export default [
    // 个人中心首页
    {
        path: 'main/index',
        meta: {
            breadcrumb: [...mainIndex],
        },
        component: () =>
            import(
                /* webpackChunkName: "main-module" */ '../../../pages/home/main/Index'
            ),
    },
];

const sellerIndex = [
    {
        name: '卖家中心',
        path: '/home/seller/index',
        style: {
            color: 'rgba(0, 191, 191, 1)',
        },
        icon: 'ios-alert',
    },
];

// 买家相关
export default [
    // 首页
    {
        path: 'seller/index',
        meta: {
            breadcrumb: [...sellerIndex],
        },
        component: () => import('@/pages/home/seller/Index'),
    },
    {
        path: 'seller/bidIndex',
        meta: {
            breadcrumb: [...sellerIndex],
        },
        component: () => import('@/pages/home/seller/BidIndex'),
    },
    // seller 查看
    {
        path: 'seller/view',
        meta: {
            breadcrumb: [
                ...sellerIndex,
                {
                    name: '查看',
                },
            ],
        },
        component: () =>
            import(
                /* webpackChunkName: "buyer-module" */ '../../../pages/home/seller/View'
            ),
    },
    {
        path: 'seller/bid',
        meta: {
            breadcrumb: [
                ...sellerIndex,
                {
                    name: '抢单',
                },
            ],
        },
        component: () => import('@/pages/home/seller/Bid'),
    },
];

// 类型
const productCategoryType = [
    { text: '试剂', value: '1' },
    { text: '耗材', value: '2' },
    { text: '仪器', value: '3' },
];

// 采购平台
const purchasePlatform = [
    { text: '喀斯玛', value: '1' },
    { text: '锐竞', value: '2' },
    { text: '库巴扎', value: '3' },
    { text: '方元', value: '4' },
    { text: '虫洞', value: '5' },
    { text: '科研物资', value: '6' },
    { text: '云采通', value: '7' },
    { text: '山东高校平台', value: '8' },
    { text: '供应室', value: '9' },
    { text: '基理', value: '10' },
    { text: '东篱', value: '11' },
];

// 企业类型
const companyTypes = [
    { text: '高校', value: 1 },
    { text: '企业', value: 2 },
];
// 优先类型
const priorityType = [
    { text: '价格', value: '1' },
    { text: '货期', value: '2' },
    { text: '效期', value: '3' },
];
// 响应时间
const expireTimeType = [
    { text: '30分钟', value: '30' },
    { text: '60分钟', value: '60' },
    { text: '120分钟', value: '120' },
];
// 延时时间
const extensionType = [
    { text: '60分钟', value: '60' },
    { text: '180分钟', value: '180' },
];
const expireTimeShowType = [
    { text: '30分钟', value: '30' },
    { text: '60分钟', value: '60' },
    { text: '90分钟', value: '90' },
    { text: '120分钟', value: '120' },
    { text: '150分钟', value: '150' },
    { text: '180分钟', value: '180' },
    { text: '300分钟', value: '300' },
];

// 发票类型
const invoiceType = [
    { text: '普通电子发票', value: '1' },
    { text: '普通纸质发票', value: '2' },
];

// 采购单位
const unitType = [
    { text: '个', value: '个' },
    { text: '盒', value: '盒' },
    { text: '箱', value: '箱' },
];

// 资质类型
const agentLevel = [
    { text: '生产厂家', value: '1' },
    { text: '一级代理', value: '2' },
    { text: '经销商', value: '3' },
    // { text: '其他', value: '4' },
    // { text: '全部', value: '5' },
];

// 默认登录身份
const defaultIdentity = [
    { text: '买家', value: '1' },
    { text: '卖家', value: '2' },
];

// 现货类型
const stockType = [
    { text: '国内现货', value: 1 },
    { text: '非国内现货', value: 2 },
];

// 机构类型
const orgType = [
    { text: '科研机构', value: 1 },
    { text: '经销商', value: 2 },
    { text: '终端企业', value: 3 },
];

export default {
    options: {
        productCategoryType,
        purchasePlatform,
        priorityType,
        expireTimeType,
        invoiceType,
        companyTypes,
        extensionType,
        expireTimeShowType,
        agentLevel,
        unitType,
        defaultIdentity,
        stockType,
        orgType,
    },
    optionsMap: {
        productCategoryType: productCategoryType.reduce((cur, it) => {
            cur[it.value] = it.text;
            return cur;
        }, {}),
        purchasePlatform: purchasePlatform.reduce((cur, it) => {
            cur[it.value] = it.text;
            return cur;
        }, {}),
        companyTypes: companyTypes.reduce((cur, it) => {
            cur[it.value] = it.text;
            return cur;
        }, {}),
        priorityType: priorityType.reduce((cur, it) => {
            cur[it.value] = it.text;
            return cur;
        }, {}),
        expireTimeType: expireTimeType.reduce((cur, it) => {
            cur[it.value] = it.text;
            return cur;
        }, {}),
        expireTimeShowType: expireTimeShowType.reduce((cur, it) => {
            cur[it.value] = it.text;
            return cur;
        }, {}),
        extensionType: extensionType.reduce((cur, it) => {
            cur[it.value] = it.text;
            return cur;
        }, {}),
        invoiceType: invoiceType.reduce((cur, it) => {
            cur[it.value] = it.text;
            return cur;
        }, {}),
        agentLevel: agentLevel.reduce((cur, it) => {
            cur[it.value] = it.text;
            return cur;
        }, {}),
        unitType: agentLevel.reduce((cur, it) => {
            cur[it.value] = it.text;
            return cur;
        }, {}),
        defaultIdentity: defaultIdentity.reduce((cur, it) => {
            cur[it.value] = it.text;
            return cur;
        }, {}),
        stockType: stockType.reduce((cur, it) => {
            cur[it.value] = it.text;
            return cur;
        }, {}),
        orgType: orgType.reduce((cur, it) => {
            cur[it.value] = it.text;
            return cur;
        }, {}),
    },
};

<template>
    <div class="login-page flex-center flex-v-center bg-red">
        <div
            class="login-content"
            :class="isRegister ? 'login-content-reg' : ''"
        >
            <div class="tc">
                <img height="50" src="../../assets/img/logo/white.png" alt="" />
            </div>

            <div v-if="!isRegister" class="login-tab-content">
                <Tabs v-model="loginType" class="login-tab-com mb10">
                    <TabPane label="账户密码登录" name="account" />
                    <TabPane label="手机号登录" name="phone" />
                </Tabs>
                <!--                <LoginTabs v-model="loginType" />-->
                <AccountLogin v-if="loginType === 'account'" />
                <PhoneLogin v-if="loginType === 'phone'" />

                <div class="flex-end">
                    <span class="c-red pointer" @click="isRegister = true"
                        >注册账号</span
                    >
                </div>
            </div>
            <div v-if="isRegister" class="login-tab-content">
                <AccountRegister @useAccount="isRegister = false" />
            </div>
        </div>
    </div>
</template>
<script>
import AccountLogin from './component/AccountLogin.vue';
import PhoneLogin from './component/PhoneLogin.vue';
import AccountRegister from './component/AccountRegister.vue';
// import LoginTabs from "./component/LoginTabs.vue";

export default {
    components: { AccountRegister, PhoneLogin, AccountLogin },
    data() {
        return {
            loginType: 'account', // 默认账号登录
            isRegister: false,
        };
    },
};
</script>
<style lang="less" scoped>
.login-tab-com {
}

.login-page {
    height: 100vh;
    background: url('../../assets/img/login-bg.svg');
    background-size: cover;

    .login-content {
        width: 600px;
        &-reg {
            width: 1200px;
        }

        .login-tab-content {
            background: white;
            padding: 20px 56px;
        }
    }
}

.demo-auto-login a {
    float: right;
}
</style>

const list = (params = {}) => {
    return $api.get('/pms/orgBrand/list', params);
};
const del = (params = {}) => {
    return $api.post('/pms/orgBrand/delete', params);
};
const save = (params = {}) => {
    return $api.post('/pms/orgBrand/save', params);
};
const update = (params = {}) => {
    return $api.post('/pms/orgBrand/update', params);
};
const getById = (params = {}) => {
    return $api.get('/pms/orgBrand/getById', params);
};

export default { list, delete: del, save, update, getById };

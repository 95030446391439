<template>
    <div class="htp100 root-sub-content">
        <router-view></router-view>
    </div>
</template>

<script>
// 生成自己的router容器 方便内部使用
import currentOrgMixin from '@/mixins/currentOrg.mixin';

export default {
    mixins: [currentOrgMixin],
    computed: {
        breadcrumb() {
            return _.get(this.$route, 'meta.breadcrumb') || [];
        },
    },
};
</script>

<style scoped lang="less">
.root-sub-content {
    overflow-y: scroll;
    overflow-x: hidden;
    .user-banner {
    }
}
.i-breadcrumb {
    /*border-bottom: 1px dashed gray;*/
}
</style>

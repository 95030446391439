const create = (params = {}) => {
    return $api.post('/ums/org/create', params);
};

const list = (params = {}) => {
    return $api.get('/ums/org/list', params);
};

const joinCommit = (params = {}) => {
    return $api.post('/ums/org/joinCommit', params);
};
// 获取当前登录人企业信息
const getCurrentOrg = (params = {}) => {
    return $api.get('/ums/org/getCurrentOrg', params);
};
// 添加企业信息
const addCurrentOrg = (params = {}) => {
    return $api.post('/ums/org/create', params);
};
// 更新企业信息
const updateCurrentOrg = (params = {}) => {
    return $api.post('/ums/org/update', params);
};
// 个人信息增加收货地址
const saveOrUpdateAddr = (params = {}) => {
    return $api.post('/ums/memberReceiveAddress/saveOrUpdate', params);
};
// 获取个人中心卖家信息
const getSellerInfo = (params = {}) => {
    return $api.get('/ums/org/getSellerInfo', params);
};

// 个人信息发票信息
const updateOrg = (params = {}) => {
    return $api.post('/ums/org/updateInvoice', params);
};
// 关联查询名称
const likeOrg = (params = {}) => {
    return $api.post('/ums/org/likeName', params);
};
export default {
    create,
    list,
    joinCommit,
    getCurrentOrg,
    addCurrentOrg,
    likeOrg,
    updateCurrentOrg,
    saveOrUpdateAddr,
    updateOrg,
    getSellerInfo,
};

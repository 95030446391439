<template>
    <div class="org-name-selector">
        <Input
            clearable
            type="text"
            :value="formValue"
            class="org-name-selector-input"
            @on-change="handleChange"
            @on-focus="handleFocus"
            @on-blur="handleBlur"
        />
        <Card
            v-if="showDrop && options.length"
            class="org-name-selector-drop-wrapper"
        >
            <div class="org-name-selector-drop">
                <template>
                    <div
                        v-for="it of options"
                        :key="it.value"
                        class="org-name-selector-drop-item"
                        @click="handleSelectItem(it)"
                    >
                        {{ it.label }}
                    </div>
                </template>
                <!--                <div v-else class="org-name-selector-drop-item tc">-->
                <!--                    暂无数据-->
                <!--                </div>-->
            </div>
        </Card>
    </div>
</template>

<script>
export default {
    props: {
        value: {},
        immediate: {
            default: false,
        },
    },
    data() {
        return {
            showDrop: false,
            formValue: this.value,
            emitValue: this.value,
            options: [],
        };
    },
    mounted() {
        this.handleSearch();
    },
    methods: {
        async handleSelectSearchValue(name) {
            this.formValue = name;
            this.handleUpdate(this.formValue, true);

            await this.handleSearch();
        },
        handleUpdate(value, confirm = false) {
            this.emitValue = value;
            if (confirm || !value) {
                this.$emit('input', value);
            }
        },
        handleSelectItem(item) {
            this.handleUpdate(item.value);
            this.formValue = item.label;
        },
        handleChange(e) {
            this.formValue = e.target.value.trim();
            this.handleUpdate(this.formValue);
            this.handleSearch();
        },
        handleFocus() {
            this.showDrop = true;
            this.handleSearch();
        },
        syncValue() {
            this.showDrop = false;
            this.handleUpdate(this.emitValue, true);
        },
        handleBlur() {
            if (this.immediate) {
                this.syncValue();
                return;
            }

            setTimeout(() => {
                this.syncValue();
            }, 300);
        },
        //扩展信息下拉
        async handleSearch() {
            let keywords = this.formValue;
            if (!keywords) {
                this.options = [];
                return;
            }

            let res = await $service.org.likeOrg({ name: keywords });
            const list = (_.get(res, 'data') || []).map((item) => {
                return { value: item.id, label: item.name, item };
            });

            this.options = list.filter(
                (item) =>
                    item.label.toLowerCase().indexOf(keywords.toLowerCase()) >
                    -1,
            );

            // 匹配字段进行赋值
            for (let it of this.options) {
                if (it.label === keywords) {
                    this.handleUpdate(it.value, true);
                    break;
                }
            }
        },
    },
};
</script>
<style lang="less" scoped>
.org-name-selector {
    position: relative;
    background: white;
    &-input {
    }

    .org-name-selector-drop-wrapper {
        margin-top: 4px;
        position: absolute;
        width: 100%;
        z-index: 100;
        cursor: pointer;

        /deep/ .ivu-card-body {
            padding: 0;
        }
    }

    &-drop {
        max-height: 100px;
        overflow-y: scroll;
        &-item {
            padding: 6px;

            &:hover {
                background: #f3f3f3;
            }
        }
    }
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import path from 'path';

Vue.use(Vuex);

let moduleFiles = require.context('./modules/', true, /\.js$/);

let modules = moduleFiles.keys().reduce((md, cur) => {
    let name = path.basename(cur).replace(path.extname(cur), '');
    md[name] = moduleFiles(cur).default;
    return md;
}, {});

export default new Vuex.Store({
    modules,
    debug: process.env.NODE_ENV !== 'production',
});
